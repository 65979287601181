
import './App.css';

import React, { useState, useEffect } from 'react';

import { Pressable, ImageBackground, Platform, Share, SafeAreaView, ScrollView, Text, Linking, View, Image, StyleSheet, FlatList } from 'react-native';

import { storage } from './firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage'; 

// Implemented SEO meta tagging directly in index.html instead of the below dynamic for now
//import SEO from './SEO';


const App = () => {

  //April 3: All vendors listing update
  const [vendors, setVendors] = useState([]);

  // Add a new state to hold the image URLs
  const [imageUrls, setImageUrls] = useState({});

  
  
  useEffect(() => {

    // Define an async function to fetch all image URLs
    // Change to take game icons from firebase storage
    const fetchImageUrls = async () => {
      //const indexPath = 'input-files/json_data_storage_paths.json';
      const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH;
      const indexRef = ref(storage, indexPath);
      console.log("Fetched indexRef:", indexRef);

      const indexUrl = await getDownloadURL(indexRef);
      console.log("Fetched indexUrl:", indexUrl);

      const indexResponse = await fetch(indexUrl);
      const indexData = await indexResponse.json();
      
      const urls = {};
      for (const filePath of indexData.files) {
        const vendorName = filePath.split('/')[4].toLowerCase(); // Assuming this is the vendor's name
        const imageRef = ref(storage, `${process.env.REACT_APP_FIREBASE_STORAGE_CAT_ICONS_PATH}/${vendorName}-${process.env.REACT_APP_CATEGORY}-icon.png`);

        urls[vendorName] = await getDownloadURL(imageRef);
      }
      setImageUrls(urls);
    };

    const fetchIndexAndProducts = async () => {
      try {
        //const indexPath = 'input-files/json_data_storage_paths.json';
        const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH;
        const indexRef = ref(storage, indexPath);
        const indexUrl = await getDownloadURL(indexRef);
        const indexResponse = await fetch(indexUrl);
        const indexData = await indexResponse.json();

        const vendorPromises = indexData.files.map(async filePath => {
          try {
            const fileRef = ref(storage, filePath);
            const fileUrl = await getDownloadURL(fileRef);
            const fileResponse = await fetch(fileUrl);
            const products = await fileResponse.json();

            if (products.length === 0) {
              console.log(`${filePath} is empty.`);
              return null; // Skip processing for empty files
            }

            const vendorName = filePath.split('/')[4]; // Adjust this to extract the vendor name correctly
            console.log("Fetched vendor name:", vendorName);

            return { vendorName, products: products.map(product => ({ ...product, vendorName })) };
          } catch (error) {
            console.error(`Error fetching products for ${filePath}:`, error);
            return null; // Continue with the next file in case of error
          }
        });

        const vendorsData = (await Promise.all(vendorPromises)).filter(vendor => vendor !== null);
        setVendors(vendorsData);
      } catch (error) {
        console.error('Fetching index file failed:', error);
      }
    };

    fetchIndexAndProducts();

    // Change to take images from firebase storage
    fetchImageUrls(); // Fetch image URLs when the component mounts

  }, []);
  

  const renderProduct = ({ item }) => {
    //console.log(item.vendorName)
    //console.log(item['Product Name'])
    // Construct the thumbnail file name based on the vendor name
    //const thumbnailUri = `assets/${item.vendorName.toLowerCase()}-game-icon.png`; // Example: "assets/bestbuy-game-icon.png"
    
    // Change to take game thumbnail images from firebase storage
    const thumbnailUri = imageUrls[item.vendorName.toLowerCase()]; // Use the pre-fetched URL
    //console.log(thumbnailUri)


    return (
      <Pressable onPress={() => item['Product Url'] && Linking.openURL(item['Product Url'])} style={styles.card}>

        <Image
          source={{ uri: thumbnailUri }} // Use the URL from state
           style={styles.thumbnail}
        />
        <View style={styles.textContainer}>
          <Text style={styles.productName} numberOfLines={2}>{item['Product Name']}</Text>
          <Text style={styles.productPrice}>Price: {item['Product Price']}</Text>
          {item['Product Discount'] ? <Text style={styles.productDiscount}>Discount: {item['Product Discount']}</Text> : null}
        </View>
      </Pressable>

    );
  };
  
  const Header = () => {
    return (
      <ImageBackground source={require(process.env.REACT_APP_CATEGORY_BACKGROUND)} style={styles.header} resizeMode="cover">

        {/*<View style={styles.header}>*/}
            <View style={styles.headerContent}>
                <Pressable onPress={() => Linking.openURL('https://nupeacock.com')}>
                  <Image source={require('./assets/nupeacock-logo.png')} style={styles.icon} />
                </Pressable>

                <View style={styles.headerText}>
                  <Text style={styles.title}>{process.env.REACT_APP_SITE_NAME}</Text>
                  <Text style={styles.subtitle}>{process.env.REACT_APP_SITE_TEXT}</Text>
                </View> 

                <View style={styles.socialIcons}>
                  {/*<Pressable onPress={() => Linking.openURL('http://www.nupeacock.com')}>
                      <Image source={require('./assets/favicon.png')} style={styles.icon} />
                  </Pressable>*/}
                  {/*
                  REACT_APP_TWITTER_URL=https://twitter.com/NuGamesPeacock
REACT_APP_FACEBOOK_URL=https://www.facebook.com/profile.php?id=61559663101394
REACT_APP_INSTAGRAM_URL=https://www.instagram.com/nugamespeacock/ */}
{/*
                  <Pressable onPress={() => Linking.openURL('https://twitter.com/NuGamesPeacock')}>
                      <Image source={require('./assets/twitter.png')} style={styles.icon} />
                  </Pressable>
                  <Pressable onPress={() => Linking.openURL('https://www.instagram.com/nugamespeacock/')}>
                      <Image source={require('./assets/instagram.jpeg')} style={styles.icon} />
                  </Pressable>
*/}
                  <Pressable onPress={() => Linking.openURL(process.env.REACT_APP_TWITTER_URL)}>
                      <Image source={require('./assets/twitter.png')} style={styles.icon} />
                  </Pressable>

                  <Pressable onPress={() => Linking.openURL(process.env.REACT_APP_INSTAGRAM_URL)}>
                      <Image source={require('./assets/instagram.jpeg')} style={styles.icon} />
                  </Pressable>

                  <Pressable onPress={() => Linking.openURL(process.env.REACT_APP_FACEBOOK_URL)}>
                      <Image source={require('./assets/facebook-icon.png')} style={styles.icon} />
                  </Pressable>

                  <Pressable onPress={() => Linking.openURL('mailto:crengana@nupeacock.com')}>
                      <Image source={require('./assets/email.png')} style={styles.icon} />
                  </Pressable>
                  <Pressable onPress={sharePage}>
                      <Image source={require('./assets/share.png')} style={styles.icon} />
                  </Pressable>
                </View>
            </View>
        {/*</View> */}
        </ImageBackground>
    );
  };

  const sharePage = () => {
    const shareData = {
        title: process.env.REACT_APP_SITE_TITLE,
        text: process.env.REACT_APP_SITE_TEXT,
        ur: process.env.REACT_APP_SITE_URL
    };

    if (Platform.OS === 'web') {
        // Web-specific sharing logic
        if (navigator.share) {
            navigator.share(shareData)
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing:', error));
        } else {
            console.log('Web Share API not supported.');
        }
    } else {
        // Mobile-specific sharing logic using React Native's Share
        Share.share(shareData)
            .then((result) => {
                if (result.action === Share.sharedAction) {
                    console.log('Share was successful');
                }
            })
            .catch((error) => console.log('Error sharing:', error));
    }
  };


  return (

  <SafeAreaView style={{ flex: 1 }}>
    {/* Commenting out the dynamic SEO meta tagging for now. Is statically done in index.html directly.
        ..until SSR..server side rendering is implemented.
    <SEO
      title="NuGames - New Video Games"
      description="Discover the new newest latest video games titles on NuGames, 
          your portal to new fun best online including free, PS5, PS4, Switch, Nintendo, Xbox, Xbox360 on Epicgames, Gogames, Bestbuy, Amazon, Humblegames, Gamestop"
      url="https://games.nupeacock.com"
      image="https://games.nupeacock.com/assets/games-pic-for-page-meta.png"
    />
  */}
    <Header />
    <ScrollView style={{ flex: 1 }}>
      {vendors.map((vendor, index) => (
        <View key={index} style={{ marginBottom: 20 }}>

{/* Removed "Titles" after {vendor.vendorName}} */}
          <Text style={{ fontSize: 20, marginLeft: 20, marginBottom: 10 }}>
            {vendor.vendorName} 
          </Text>

          <FlatList
            data={vendor.products}
            renderItem={renderProduct}
            keyExtractor={(item, idx) => `${index}-${idx}`}
            horizontal={true}
            showsHorizontalScrollIndicator={true}
            contentContainerStyle={styles.listContainer}
          />
        </View>
      ))}
      

    
    </ScrollView>
  </SafeAreaView>
  );

};

const styles = StyleSheet.create({

  header: {

    width: '100%',
    height: 400, // Adjust height as needed
    //flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between', // Ensures logo is on the left and social icons on the right
    //justifyContent: 'flex-start',
    justifyContent: 'center',
    padding: 10,
  },
  headerContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap', // Allows wrapping on mobile
  },
  logo: {
      height: 60,
      //resizeMode: 'contain',
      flexDirection: 'row',
      justifyContent: 'flex-start',
  },
  icon: {
      height: 30,
      width: 30,
      marginLeft: 10,
      borderRadius: 25,
  },
  socialIcons: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 200,
  },

  headerText: {
    flex: 1,
    //marginLeft: 15, // Adjust as needed
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 64, // Adjust to desired size
    color: 'fuchsia',
    fontWeight: 'bold',
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
    //textShadowColor: 'rgba(0, 0, 0, 0.75)',
    //textShadowOffset: { width: -1, height: 1 },
    //textShadowRadius: 10,
    textAlign: 'center',
  },
  subtitle: {
    //fontSize: 32, // Adjust to desired size
    fontSize: 24, // Smaller size for mobile, can adjust to 32 for desktop
    color: 'limegreen',
    marginTop: 5,
    //textShadowColor: 'rgba(0, 0, 0, 0.75)',
    //textShadowOffset: { width: -1, height: 1 },
    //textShadowRadius: 10,
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
    textAlign: 'center',
  },

  listContainer: {
    paddingHorizontal: 20,
    minWidth: 1000,
  },
  
  card: {
    width: 120, // Further reduced width
    marginRight: 20,
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#fff',
    elevation: 3,
    boxShadow: '1px 1px 5px rgba(51, 51, 51, 0.3)',
    // shadowOffset: { width: 1, height: 1 },
    // shadowColor: "#333",
    //shadowOpacity: 0.3,
    // shadowRadius: 2,
  },
  thumbnail: {
    width: '100%',
    height: 100, // Reduced height
  },
  textContainer: {
    padding: 5,
  },
  productName: {
    fontSize: 12, // Adjusted for smaller card size
    fontWeight: 'bold',
    textAlign: 'center',
  },
  productPrice: {
    fontSize: 12,
    color: 'green',
    textAlign: 'center',
  },
  productDiscount: {
    fontSize: 11,
    color: 'red',
    textAlign: 'center',
  },



});

export default App;
